<div class="modal fade show">
  <div class="modal-dialog" [class.modal-dialog-wide]="wide">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <ng-content select="[title]"></ng-content>
        </h5>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer">
        <ng-content select="[footer]"></ng-content>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop fade show"></div>
